<template>
	<pui-datatable
		v-if="!isSuperAdmin"
		:modelName="modelName"
		:modelColumnDefs="columnDefs"
		:externalFilter="externalFilter"
		:navigableDetail="false"
		:showDeleteBtn="false"
		:showCreateBtn="false"
	></pui-datatable>
	<pui-datatable
		v-else
		:modelName="modelName"
		:modelColumnDefs="columnDefs"
		:externalFilter="externalFilter"
		:navigableDetail="false"
		:showDeleteBtn="false"
		:showCreateBtn="false"
		:masterDetailColumnVisibles="masterDetailColumnVisibles"
	></pui-datatable>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';

import constants from '@/utils/constants';
import { isSuperAdmin } from '@/api/common';

export default {
	mixins: [PuiGridDetailMixin],
	name: 'PmactionsLogGrid',
	data() {
		return {
			modelName: 'pmactionslog',
			isSuperAdmin: false,
			columnDefs: {
				disabled: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				},
				executionresult: {
					createdCell: (td, cellData) => {
						return (td.innerHTML = `<label style="font-weight:bold;color:${this.getStatusColor(cellData)}">${cellData}</label>`);
					}
				}
			},
			externalFilter: null,
			masterDetailColumnVisibles: {
				actionname: true,
				actioncode: true,
				actiontype: true,
				devicecode: true,
				datasourcetypename: true,
				executionutc: true,
				parameterused: true,
				executionresult: true,
				errormessage: true,
				usr: true,
				orgname: true
			}
		};
	},
	computed: {},
	created() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
	},
	mounted() {
		if (!this.isSuperAdmin) {
			this.externalFilter = {
				groupOp: 'or',
				rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }],
				groups: []
			};
		}
	},
	methods: {
		getStatusColor(status) {
			switch (status) {
				case constants.ACTION_RESULT_SUCCESS:
					return '#43a047';
				case constants.ACTION_RESULT_FAILURE:
					return '#d32f2f';
				case constants.ACTION_RESULT_COMPLETED:
					return '#f9a825';
				default:
					return 'black';
			}
		}
	}
};
</script>

<style lang="postcss" scoped></style>
